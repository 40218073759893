<template>
  <section>
    <div>
      <EquipmentSearchPanel :source="source" />
      <div class="text-center" v-if="state == 'busy'" style="padding:10px">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <template v-else-if="equipmentList && equipmentList.length">
        <div class="result-table" v-if="customPanel">
          <DeviceListTablePanel
            :equipmentList="equipmentList"
            :panel="customPanel"
          />
        </div>
        <EquipmentTablePanel
          v-else
          class="result-table"
          :equipmentList="equipmentList"
          :searchEnabled="true"
          :sortEnabled="true"
          :pagination="true"
          :clientSort="true"
          :inlineFilter="true"
          @select="onSelect"
        />
      </template>
    </div>
    <!-- -->
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentTablePanel from "@/components/equipment-table-panel.vue";
import DeviceListTablePanel from "@/components/device-list-table-panel.vue";
export default {
  name: "EquipmentSearchTable",
  extends: EquipmentSearchBase,
  components: {
    EquipmentTablePanel,
    DeviceListTablePanel
  },
  props: {
    filterCriteria: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ],
      customPanel: undefined
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    customConfiguration() {
      return (
        (this?.contract?.portal_data?.search_page || {})[this.$options.name] ||
        null
      );
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || null;
    },
    state() {
      if (
        !this.contract ||
        (this.customConfiguration && this.customPanel === undefined)
      )
        return "busy";
      return "ready";
    }
  },
  watch: {
    customConfiguration: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    },
    screens: {
      handler(n) {
        if (n) {
          this.updateCustomView();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onSelect(query) {
      if (query) {
        if (query.connector_id) {
          this.showEquipmentWithId(query.connector_id);
        }
      }
    },
    showField(name) {
      return (
        this.allEquipmentFields.filter(function(i) {
          return i.name == name;
        }).length > 0
      );
    },
    customTemplate() {
      return (
        this.$store.getters["dashboard/template"](
          this.customConfiguration.screen_id
        ) || null
      );
    },
    setCustomPanel(template) {
      if (!this.customPanel) {
        let panel =
          ((template && template?.panels) || []).find(
            ({ name }) => name == this.customConfiguration.panel_name
          ) || null; // important - default null would render the default layout
        this.$set(this, "customPanel", panel);
      }
    },
    async updateCustomView() {
      if (this?.customConfiguration) {
        if (
          (this.screens || []).some(
            ({ id }) => id == this?.customConfiguration.screen_id
          )
        ) {
          let template = this.customTemplate();
          if (template) {
            this.setCustomPanel(template);
          } else {
            await this.$store
              .dispatch(
                "dashboard/fetchTemplate",
                this.customConfiguration.screen_id
              )
              .then((response) => {
                if (response) {
                  this.setCustomPanel(response);
                }
              });
          }
        } else {
          this.fetchScreens([parseInt(this?.customConfiguration.screen_id)]);
        }
      }
    }
  },
  created() {},
  mounted() {
    this.refreshEnabled = true;
  }
};
</script>
<style scoped>
.tab-search {
  position: relative;
}
.result-table {
  min-height: 400px;
}
</style>
